/*
Theme Name: QuickFood 
Theme URI: http://www.ansonika.com/quickfood/
Author: Ansonika
Author URI: http://themeforest.net/user/Ansonika/

[Table of contents] 

1. SITE STRUCTURE and TYPOGRAPHY
- 1.1 Typography
- 1.2 Buttons
- 1.3 Structure

2. CONTENT
- 2.1 Home all versions
- 2.2 List page
- 2.3 Restaurant menu
- 2.4  Order process
- 2.5 Restaurant detail page
- 2.6 About
- 2.7 Contacts

3. COMMON
3.1 Preloader
3.2 Parallax bg
3.4 Login/Register modals
3.5 Spacing
3.6  Map
3.7 Password strength
3.8 Input form style
3.9 Collapse
3.10 Tabs
3.11 Tooltips
3.12 Blockquote

4. UPDATES
4.1 Strip list home
4.2 Grid list
4.3 General
4.4 Map listing
4.5 Search modal
4.6 Restaurant menu
4.7 Work with us section

/*============================================================================================*/
/* 1.  SITE STRUCTURE and TYPOGRAPHY */
/*============================================================================================*/

/*-------- Allert for old browsers IE8 and below --------*/
.chromeframe{background:#ddd;color:#000;padding: 0.2em;position: fixed; top: 0; left: 0; text-align: center; z-index:9999; width: 100%;}
.chromeframe a{color:#ed1c24;}

/*-------- 1.1 Typography --------*/
html * {-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
body {font-size:11px; line-height:1.4; color:#555;background-color:#ffffff; font-family:"Roboto", Helvetica, Arial, sans-serif; }
h1, h2, h3, h4, h5, h6, label {color:#555555;}
h1, h2 {
	font-family: "Roboto", Helvetica, Arial, sans-serif;
	font-weight: 100;
}

/** Layes slider typography **/   
h3.slide_typo {font-family: 'Lato', "Roboto", Helvetica, Arial, sans-serif;letter-spacing:-2px; font-size: 60px; white-space:nowrap; color:#fff !important; font-weight:300;}
h3 .slide_typo strong {font-family:"Roboto", Helvetica, Arial, sans-serif;font-weight:normal;}
.slide_typo_2{color:#fff; font-size:18px; white-space:nowrap}

.box_style_2 h2.inner {
	background-color:#78cfcf; 
	color:#fff; 
	margin:-25px -25px 20px -25px; 
	padding:12px 15px; 
	font-family:"Roboto", Helvetica, Arial, sans-serif; 
	font-size:22px; 
	font-weight:600;
	-webkit-border-top-left-radius: 3px;
	-webkit-border-top-right-radius: 3px;
	-moz-border-radius-topleft: 3px;
	-moz-border-radius-topright: 3px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	text-align: center;
}
.internet-error {
	height: 30px;
    background: #ff8100;
    margin-top: 0px;
    font-size: 20px;
  }
  
  .internet-error p {
	font-size: 15px;
    line-height: 31px;
    color: #fff;
    margin: 51px;
    text-align: center;
  }
  
.main_title{ text-align:center; margin-bottom:45px; }
.main_title h2{ font-size:46px; letter-spacing:-1px; font-weight:300; margin-bottom:0;}
.main_title p{ font-size:18px; color:#555; line-height:1.2; margin-bottom:30px; font-weight:300;}

.feature h3{margin-top:0;}
.feature h3 span{color:#F7DF4E;}

/*General links color*/
a {color:#F7DF4E;text-decoration: none;-webkit-transition: all 0.2s ease;transition: all 0.2s ease; outline:none;}
a:hover,a:focus {color: #111;text-decoration: none; outline:none;}

hr.more_margin {
  margin-top: 60px;
  margin-bottom:40px;
  border: 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #fff;
}
hr.styled {
  border: 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #fff;
}

/*-------- 1.2 Buttons --------*/
a.btn_1, .btn_1{
	border: none;
	font-family: inherit;
	font-size: inherit;
	color:#fff;
	background: #85c99d;
	cursor: pointer;
	padding: 10px 20px;
	outline: none;
	font-size:12px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	text-transform:uppercase;
	font-weight:600;
	display:inline-block;
	text-align:center;
}
a.btn_1:hover, .btn_1:hover{
	background:#333;
}
a.btn_full, .btn_full{
	border: none;
	font-family: inherit;
	font-size: inherit;
	color:#fff;
	width:100%;
	background: #85c99d;
	cursor: pointer;
	padding: 12px 20px;
	outline: none;
	font-size:12px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	text-transform:uppercase;
	font-weight:bold;
	display:block;
	text-align:center;
	margin-bottom:5px;
}
a.btn_full:hover, .btn_full:hover{
	background:#333;
}
a.btn_1.medium, .btn_1.medium{
	padding: 10px 20px;
	font-size:14px;
	text-transform:none;
}
a.btn_map{
	border: none;
	font-family: inherit;
	font-size: inherit;
	color:#fff;
	background:#555;
	cursor: pointer;
	padding: 10px 20px;
	display: inline-block;
	outline: none;
	font-size:12px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	text-transform:uppercase;
	font-weight:bold;
	display:block;
	text-align:center;
	margin-bottom:25px;
}
a.btn_map:hover{
	background:#333;
}
a.btn_side{
	border: none;
	font-family: inherit;
	font-size: inherit;
	color:#fff;
	background:#555;
	cursor: pointer;
	padding: 10px 20px;
	display: inline-block;
	outline: none;
	font-size:12px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	text-transform:uppercase;
	font-weight:bold;
	display:block;
	text-align:center;
	margin-bottom:25px;
}
a.btn_side:hover{
	background:#333;
	color:#fff;
}
a.btn_full_outline, .btn_full_outline{
	border: none;
	font-family: inherit;
	font-size: inherit;
	color:#85c99d;
	background:none;
	width:100%;
	border:2px solid #85c99d;
	cursor: pointer;
	padding: 10px 20px;
	display: inline-block;
	outline: none;
	font-size:12px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	text-transform:uppercase;
	font-weight:bold;
	display:block;
	text-align:center;
}
a.btn_full_outline:hover, .btn_full_outline:hover{
	border:2px solid #333;
	color:#333;
}
a.button_intro, .button_intro  {
	border: none;
	font-weight:normal;
	font-style:normal;
	color:#fff !important;
	background: #85c99d;
	cursor: pointer;
	padding: 12px 25px;
	display: inline-block;
	outline: none;
	font-size:13px;
	text-align:center;
	-webkit-transition: background 0.3s;
	-moz-transition: background 0.3s;
	transition: background 0.3s;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	text-transform:uppercase;
	min-width:110px;
	font-weight:bold;
}
a.button_intro:hover, .button_intro:hover {
	background: #fff;
	color:#85c99d !important;
}
a.button_intro.outilne, .button_intro.outline  {
	border: 2px solid #fff;
	color:#fff;
	background: none;
	padding: 10px 23px;
}
#cart_box a.btn_full, #cart_box .btn_full{
	background: #F7DF4E;
	font-size:15px;
}
#cart_box a.btn_full:hover, #cart_box .btn_full:hover{
	background: #333;
}
#cart_box a.btn_full_outline{
	color:#F7DF4E;
	background:none;
	border:2px solid #F7DF4E;
}
#cart_box a.btn_full_outline:hover{
	color:#333;
	background:none;
	border:2px solid #333;
}

/*-------- 1.3 Structure --------*/

/* Header */
header{
	width:100%;
	position:fixed;
	padding-top:15px;
	top:0;
	left:0;
	z-index:99999;
}
header.sticky{
	background-color: #FFFFFF;
	-webkit-box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.41);
	-moz-box-shadow:0px 2px 10px -2px rgba(0, 0, 0, 0.41);
	box-shadow:0px 2px 10px -2px rgba(0, 0, 0, 0.41);
}
#subheader{
	color:#fff;
	text-align:center;
	  display: table;
   width:100%;
   height:200px;
   background: rgba(0,0,0,.7);
}
#home #subheader{
   height:550px;
}
#short #subheader{
   height:350px;
}
#subheader a{
	color: #FF9;
}
#subheader a:hover{
	text-decoration:underline;
}

/* Footer  */
footer{
	background-color:#333333;
	font-size:11px;
	color:#ffffff;
	border-top: 1px solid #ededed;
	padding:35px 0;
}
footer h3{
	color:#ffffff;
	font-size:16px;
	font-weight:600;
}
footer a{
	color:#ffffff;
}
footer a:hover{
	color:#F7DF4E;
}
footer ul{
	margin:0;
	padding: 0 0 20px 0;
	list-style:none;
}
footer .btn_1{
	text-transform:none;
	padding:8px 15px;
	background:#555;
}
#social_footer{
	text-align:center;
	border-top:1px solid #ededed;
	padding-top:30px;
	margin-top:30px;
}
#social_footer p{
	font-size:12px;
	color:#ffffff;
}
#social_footer ul{
	margin:0;
	padding:0 0 10px 0;
	text-align:center;
}
#social_footer ul li{
	display:inline-block;
	margin:0 5px 10px 5px;
}
#social_footer ul li a{
	color:#666;
	text-align:center;
	line-height:34px;
	display:block;
	font-size:16px;
	width:35px;
	height:35px;
	background-color:#f2f2f2;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}
#social_footer ul li a:hover{
	background:#fff;
	color:#111;
}
a#promote{
	display:inline-block;
	position:relative;
	background:#F7DF4E;
	position:relative; 
	color:#fff;
	padding:10px 15px 10px 42px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	position:relative;
	margin-top:10px;
	font-weight:600;
}
a#promote:hover{
	background-color:#333;
}
a#promote:before{
	content: "\e003";
	font-family: 'ElegantIcons';
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	font-size:24px;
	position:absolute;
	left:10px;
	top:6px;
}
/* Drop down select footer */
footer .styled-select select {
   background: transparent;
   width:115%;
   padding:7px 5px 5px 15px;
   border: 0;
   border-radius: 0;
   height: 37px;
   margin:0;
  	font-weight:400;
	-moz-appearance: window;
    -webkit-appearance: none;
    cursor: pointer;
	color:#444;
	box-shadow:none;
}
footer .styled-select {
   width: 100%;
   overflow:hidden;
   height: 40px;
   background: #f8f8f8 url(../images/arrow_down.svg) no-repeat  right center ;
   border:none;
	margin-bottom:15px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
} 
.styled-select select::-ms-expand {display: none;}
.styled-select select:focus { outline:none;}

/* Menu 2 */
.main-menu-2 ul{
	list-style:none;
	margin:0 0 17px 0;
	padding:0;
	float:right;
	 font-size:14px; 
	 font-weight:500;
}
.main-menu-2 ul li{
	display:inline-block;
	margin-left:15px;
}
.main-menu-2 ul li a{
	color:#fff;
}  

/*============================================================================================*/
/* 2.  CONTENT  */

/* 2.1 Home  */
#custom-search-input {
        margin:0;
        top: 10px;
		padding: 0;
		border: 1px solid rgba(255,255,255,0.15);		

		width:500px;
		
}
#custom-search-input .search-query {
		width:500px;
		padding:15px 30px;
		
		font-family:"Roboto", Helvetica, Arial, sans-serif;
		font-weight:400;
		color:#444;
}
#custom-search-input input.btn_search{
	position:absolute;
	right:20px;
	top:13px;
	border:0;
	width:25px;
	height:25px;
	background: url(../images/search.svg) no-repeat center right;
	cursor:pointer;
}
#custom-search-input input.btn_search:focus, #custom-search-input .search-query:focus{outline:none;}
#custom-search-input input.btn_search:focus, #custom-search-input .search-query:focus{outline:none;}
#count{
	background-color:rgba(0,0,0,0.25);
	width:100%;
	position:absolute;
	bottom:0;
	left:0;
	padding:15px 0;
	color:#fff;
	font-size:18px;
	z-index:99;
	border-top: 1px solid rgba(255,255,255,0.15)
}
#count ul{
	list-style:none;
	padding:0;
	margin:0;
	text-align:center;
}
#count ul li{
	display:inline-block;
	text-align:center;
	margin:0 10px;
	padding-left:20px;
	background:url(../images/dots_vert.png) center left no-repeat;
}
#count ul li:first-child{
	margin-left:0;
	padding-left:0;
	background:none;
}
#count ul li:last-child{
	margin-right:0;
}
#count .number{
	width:auto;
	display:inline-block;
	margin-right:5px;
	font-weight:600;
	text-align:right;
	font-size:24px;
}
.header-video {
  position: relative;
  overflow: hidden;
   background: #000;
}
#hero_video {
	position: relative;
    background-size: cover;
    color:#fff;
	width: 100%;
	font-size:16px;
	display:table;
	height:100%;
	z-index:99;
	text-align:center;
}
#hero_video > div{
	display: table-cell;
	vertical-align: middle;
	text-align:center;
	padding:0 10%;
}
#hero_video > div h1{
	margin-top:-60px;
}
video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity:0.5;
}
iframe {
  height: 100%;
  width: 100%;
}
video {
  width: 100%;
}
.teaser-video {
  width: 100%;
  height: auto; 
}
.header-video--media {
  width: 100%;
  height: auto;
}
.box_home{
	background:#fff;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	border: 1px solid #ededed;
	margin-bottom:25px;
	padding:120px 25px 25px 25px;
	text-align:center;
	position:relative;
	font-size:14px;
	color:#666;
	min-height:235px;
}
.box_home span{
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	border: 1px solid #ededed;
	background-color:#fff;
	position:absolute;
	top:-20px;
	display:block;
	width:40px;
	height:40px;
	line-height:38px;
	font-weight:bold;
	font-size:16px;
	left:50%;
	margin-left:-20px;
}
.box_home#one{
	background:#fff url(../images/icon_home_1.svg) no-repeat center 40px;
}
.box_home#two{
	background:#fff url(../images/icon_home_2.svg) no-repeat center 40px;
}
.box_home#three{
	background:#fff url(../images/icon_home_3.svg) no-repeat center 40px;
}
.box_home#four{
	background:#fff url(../images/icon_home_4.svg) no-repeat center 40px;
}
.box_home h3{
	font-size:16px;
	margin-bottom:3px;
	font-weight:bold;
}
#delivery_time{
	width:500px;
	margin:auto;
	text-align:center;
	margin-top:25px;
}
#delivery_time strong span{
	background:url(../images/time_panel.svg) no-repeat 0 0;
	display: inline-block;
	width:80px;
	margin-left:10px;
	height:120px;
	font-size:72px;
	line-height:120px;
	text-shadow: 1px 1px 2px rgba(255, 255, 255, 1);
}
.high_light{
	padding:60px 0 60px 0;
	background:#85c99d;
	color:#fff;
	font-size:16px;
}
.high_light p{
	margin-bottom:0;
}
.high_light > div{
	position:relative;
}
.high_light h3{
	font-family: 'Lato', "Roboto", Helvetica, Arial, sans-serif;
	color:#fff;
	font-weight:300;
	font-size:42px;
	margin:0;
	padding:0;
}
.high_light a{
	border: none;
	font-family: inherit;
	font-size: inherit;
	color:#fff;
	background:none;
	border:2px solid #fff;
	cursor: pointer;
	padding: 15px 30px;
	outline: none;
	font-size:14px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	text-transform:uppercase;
	font-weight:bold;
	display: inline-block;
	margin-top:15px;
	text-align:center;
	position:absolute;
	right:15px;
	top:0;
}
.high_light a:hover{
	background:#333;
	color:#fff;
	border:2px solid #333;
}

/* 2.2 List page*/
#map{
	width:100%; 
	height:450px; 
	border-bottom:1px solid #ddd;
}
#filters_col{
	background-color:#fff;
	padding:20px;
	 border: 1px solid #ededed;
	 -webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	margin-bottom:25px;
}
#filters_col label{
	font-weight:normal;
}
a#filters_col_bt {
	display:block;
	color:#444;
	font-weight:600;
}
a#filters_col_bt .icon_set_1_icon-65{
	font-size:20px;
	position:relative;
	top:0;
	margin-right:5px;
}
.filter_type h6{
	border-top: 1px solid #ddd;
	margin:15px 0;
	padding:15px 0 0 0;
}
.filter_type ul{
	list-style:none;
	padding:0;
	margin:0 0 15px 0;
}
.filter_type ul li .rating{
	font-size:16px;
	left:-7px;
	position:relative;
	top:3px;
	color:#ccc;
}
.filter_type ul li .color_1, .filter_type ul li .color_2, .filter_type ul li .color_3, .filter_type ul li .color_4, .filter_type ul li .color_5, .filter_type ul li .color_6, .filter_type ul li .color_7, .filter_type ul li .color_8{
	width:8px;
	height:8px;
	margin:5px 5px 0 0;
	float:right;
	 -webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}
.filter_type ul li .color_1{
	background-color:#7dccc7;
}
.filter_type ul li .color_2{
	background-color:#f29bc1;
}
.filter_type ul li .color_3{
	background-color:#8494c8;
}
.filter_type ul li .color_4{
	background-color:#8dc63f;
}
.filter_type ul li .color_5{
	background-color:#84c99e;
}
.filter_type ul li .color_6{
	background-color:#f5977d;
}
.filter_type ul li .color_7{
	background-color:#72d0f4;
}
.strip_list{
	background-color:#fff;	
	position:relative;
	border-bottom: 1px solid #ededed;
	min-height:135px;	
	display:block;
}
.strip_list.last{
	margin-bottom:25px;
}
a.strip_list:hover h3{
	color:#F7DF4E;
}
.desc{
	padding-left:135px;
	position:relative;
}
.desc .rating{
	color:#ccc;
	padding-top:0;
}
.desc h3{
	margin:0;
	padding:0;
}
.desc h3 a{
	color:#333;
}
.desc h3 a:hover{
	color:#F7DF4E;
}
.desc .type{
	font-style:italic;
	color:#777;
}
.desc .opening{
	color:#C33;
}
.desc ul{
	list-style:none;
	margin:3px 0 0 0;
	padding:0;
}
.desc ul li{
	display:inline-block;
	margin-right:15px;
}
.desc ul li i.ok{
	color:#6C3;
	position:relative;
	top:2px;
	left:5px;
	font-size:14px;
}
.desc ul li i.no{
	color: #C30;
	position:relative;
	top:2px;
	left:5px;
	font-size:14px;
}
.thumb_strip{
	position:absolute;
	left:0;
	top:0;
	width:110px;
	height:90px;
	overflow:hidden;
	padding:5px;
	text-align:center;
	box-sizing: border-box;
}
.thumb_strip img{
	width:98px;
	height:auto;
	position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.strip_list .go_to{
	display:table;
	height:112px;
	text-align:right;
	width:100%;
}
.strip_list .go_to div{
	display: table-cell;
	vertical-align:middle;
}

.ribbon_1{
	position:absolute;
	text-indent:-9999px;
	top:-5px;
	left:-5px;
	background:url(../images/ribbon_popular.png) no-repeat 0 0;
	width:81px;
	height:80px;
	z-index:3;
}

/* 2.3 Restaurant menu*/
.box_style_1{
	background:#fff;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	 border: 1px solid #ededed;
	 margin-bottom:30px;
}
.box_style_2, #cart_box{
	background:#fff;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	 border: 1px solid #ededed;
	 margin-bottom:25px;
	 padding:25px;
}
.box_style_2#main_menu, .box_style_2.info{
	padding-bottom:10px;
}
.box_style_2#help{
	text-align:center;
}
.box_style_2#help i{
	font-size:52px;
	display:inline-block;
	color:#999;
}
.box_style_2#help a.phone{
	font-size:26px;
	display:block;
	margin-bottom:0;
	color:#85c99d;
}
#cart_box{
	background:#fff;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	 border: 1px solid #ededed;
	 margin: inherit;
	 padding:15px;
}
#cart_box h3{
	background:#f8f8f8;
	margin:-15px -15px 15px -15px;
	padding:15px;
	-webkit-border-top-left-radius: 3px;
	-webkit-border-top-right-radius: 3px;
	-moz-border-radius-topleft: 3px;
	-moz-border-radius-topright: 3px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	font-size:18px;
}
a.remove_item{
	color:#ddd;
	font-size:14px;
}
a.remove_item:hover{
	color: #4CAF50;
}
#cart_box hr {
	margin-left:-15px;
	margin-right:-15px;
	border-color:#ededed;
}
#main_menu hr {
	margin-left:-25px;
	margin-right:-25px;
	margin-top:10px;
	border-color:#ededed;
}
#order_process hr {
	margin-left:-25px;
	margin-right:-25px;
	margin-top:10px;
	border-color:#ededed;
}
#main_menu h3 {
	text-transform:uppercase;
	font-size:20px;
}
ul#cat_nav{
	list-style:none;
	margin: 0;
	padding:0;
}
ul#cat_nav li{
	 border-bottom: 1px solid #ededed;
}
ul#cat_nav li a{
	position:relative;
	color:#555;
}
ul#cat_nav li a span{
	font-size:11px;
	color:#999;
}
ul#cat_nav li a:after{
	font-family: "fontello";
	content: "\e89b";
	position:absolute;
	right:15px;
	top:15px;
}
ul#cat_nav li:last-child{
	 border-bottom: 0;
	 padding-bottom:0;
}
ul#cat_nav li:first-child a:hover, ul#cat_nav li:first-child a.active{
	-webkit-border-top-left-radius: 3px;
	-webkit-border-top-right-radius: 3px;
	-moz-border-radius-topleft: 3px;
	-moz-border-radius-topright: 3px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}
ul#cat_nav li:last-child a:hover, ul#cat_nav li:last-child a.active{
	-webkit-border-bottom-right-radius: 3px;
	-webkit-border-bottom-left-radius: 3px;
	-moz-border-radius-bottomright: 3px;
	-moz-border-radius-bottomleft: 3px;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}
ul#cat_nav li a{
	display:block;
	padding:15px 10px;
}
ul#cat_nav li a:hover, ul#cat_nav li a.active{
	background:#f9f9f9;
	color:#111;
}
#sub_content{
	 display: table-cell;
  	padding:0 15%;
    vertical-align: middle;
	text-align:center;
	font-size:16px;
}
#sub_content #thumb{
	width:130px;
	height:130px;
	overflow:hidden;
	text-align:center;
	padding-top:5px;
	box-sizing: border-box;
	margin:auto;
	margin-bottom:15px;
	background-color:#fff;
}
#sub_content #thumb img{
	width:120px;
	height:auto;
}
#sub_content h1{

	font-size:45px;
	font-weight:100;
	color:#000000;
}
.rating{
	color:#fff;
	padding-top:10px;
}
.rating .voted{
	color: #FC0;
	margin-right:3px;
}
ul.additional_links{
	list-style:none;
	margin:0;
	padding:0;
}
ul.additional_links li{
	display:inline-block;
	border-left: 1px solid #ededed;
	margin-left:3px;
	padding-left:5px;
}
ul.additional_links li:first-child{
	border-left: none;
	padding-left:0;
	margin-left:0;
}
.table.cart-list td h5{
	margin-bottom:0;
	font-weight:600;
}
.table.cart-list{
	margin-bottom:0;
}
.table.cart-list td{
	vertical-align:middle;
}

.table.table_summary td {
	border-top:0
}
.table.table_summary td.total{
	font-weight:bold;
	font-size:18px;
}
.table td.total_confirm{
	font-weight:bold;
	font-size:18px;
	background-color:#333;
	color:#fff;
}
.options i{
	font-size:26px;
}
.row#options_2{
	margin-bottom:-10px;
	margin-top:-5px;
}
#options_2 label{
	font-size:12px;
}
.thumb_cart{
  border: 1px solid #ddd;
  overflow:hidden;
   width:60px;
   height:60px;
   margin-right:10px;
   float:left;
}
.thumb_cart img{
  padding:1px;
  width:58px;
  height:auto;
}
.item_cart{
	display: inline-block;
	margin-top:20px;
}

/* 2.4 Order process */
.bs-wizard { width:90%;margin:auto;}
.bs-wizard > .bs-wizard-step {padding: 0; position: relative;}
.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {font-size: 14px; margin-bottom: 5px;}
.bs-wizard > .bs-wizard-step .bs-wizard-info {color: #999; font-size: 14px;}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot {position: absolute; width: 30px; height: 30px; display: block; background: #fbe8aa; top: 45px; left: 50%; margin-top: -11px; margin-left: -15px; border-radius: 50%;} 
.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {content: ' '; width: 14px; height: 14px; background: #e04f67; border-radius: 50px; position: absolute; top: 8px; left: 8px; } 
.bs-wizard > .bs-wizard-step > .progress {position: relative; border-radius: 0px; height: 8px; box-shadow: none; margin: 23px 0;}
.bs-wizard > .bs-wizard-step > .progress > .progress-bar {width:0px; box-shadow: none; background: #fbe8aa;}
.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {width:100%;}
.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {width:50%;}
.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {width:0%;}
.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {width: 100%;}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {background-color: #f5f5f5;}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {opacity: 0;}
.bs-wizard > .bs-wizard-step:first-child  > .progress {left: 50%; width: 50%;}
.bs-wizard > .bs-wizard-step:last-child  > .progress {width: 50%;}
.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot{ pointer-events: none; }

#confirm{
	text-align:center;
	margin-bottom:25px;
}
#confirm i{
	font-size:140px;
	color: #85c99d;
	display:inline-block;
}

/*2.5 Restaurant detail page*/
#Img_carousel .sp-selected-thumbnail {border-bottom: 2px solid #F7DF4E;}
#summary_review{
	margin:30px 0 40px 0;
	padding:25px 25px 10px 25px;
	background-color:#f1fafa;
}
#general_rating{
	margin:0 0 20px 0;
	font-weight:600;
	font-size:16px;
}
#general_rating .rating{
	display:inline;
	color:#ddd;
	font-size:20px;
}
#rating_summary ul{
	list-style:none;
	margin:0 0 0 0;
	padding:0;
}
#rating_summary ul li{
	font-size:14px;
	margin-bottom:5px;
}
#rating_summary ul li .rating{
	display:inline-block;
	float:right;
	padding:0;
	color:#ddd;
}
.review_strip_single{
	position:relative;
	padding:30px 0 20px 0;
	margin:30px 0 25px 0;
	border-bottom:1px solid #ededed;
}
.review_strip_single .rating{
	color:#ddd;
	font-size:12px !important;
}
.review_strip_single.last{
	margin-bottom:0;
	border-bottom:0;
}
.review_strip_single img{
	top:-15px;
	position:absolute;
	left:0;
	border:4px solid #ededed;
}
.review_strip_single h4{
	font-size:18px;
	margin:-12px 0 35px 90px;
	padding:0;
}
.review_strip_single small{
	float:right;
	font-size:12px;
	margin-top:-10px;
	font-style:italic;
}
.review_strip_single .rating{
	font-size:16px;
}
ul.opening_list{
	list-style:none;
	margin:0;
	padding:0;
}
ul.opening_list{
	list-style:none;
	margin:20px 0 0 0;
	padding:0;
}
ul.opening_list li{
	margin-bottom:8px;
	font-weight:600;
}
ul.opening_list li span{
	float:right;
	font-weight:400;
}
/*2.6 About*/
.features-bg {
    position: relative;
    min-height: 400px;
    background: url(../images/features-intro-01.jpg) no-repeat center center;
    background-size: cover;
}
.features-img {
    width: 100%;
    height: 400px;
    text-align: center;
    line-height: 400px;
}
.features-content {
    position: relative;
    padding: 11% 100px 10% 100px;
    height: 400px;
    background-color: #78cfcf;
	color:#fff;
	font-size:16px;
}
.features-content h3{
   font-family: "Gochi Hand", cursive;
   color:#fff;
   font-size:32px;
   line-height:34px;
   text-transform:uppercase;
}
.feature {
	padding:30px 30px 20px 120px;
	position:relative;
	background:#fff;
	margin-bottom:30px;
	color:#666;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
}
.feature i{
	margin:0;
	position:absolute; 
	top:30px;
	left:30px;
	padding:0;
	margin:0;
	width:65px;
	height:65px;
	line-height:60px;
	text-align:center;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	border:1px dashed #ccc;
	font-size:34px;
	color:#6dcff6;
}

/*2.7 Contacts*/
#contacts a{
	font-size:18px;
}
/*============================================================================================*/
/* 3.  COMMON  */
/*============================================================================================*/
/*3.1 Preloader*/
#preloader {
	position: fixed;
	top:0;
	left:0;
	right:0;
	width:100%;
	height:100%;
	bottom:0;
	background-color:#78cfcf; 
	z-index:999999; 
}
.sk-spinner-wave.sk-spinner {
  margin:-15px 0 0 -25px; 
  position:absolute;
  left:50%; 
  top:50%;
  width: 50px;
  height: 30px;
  text-align: center;
  font-size: 10px;
 }
.sk-spinner-wave div {
  background-color: #fff;
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
   animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
}
.sk-spinner-wave .sk-rect2 {-webkit-animation-delay: -1.1s;animation-delay: -1.1s;}
.sk-spinner-wave .sk-rect3 {-webkit-animation-delay: -1s;animation-delay: -1s;}
.sk-spinner-wave .sk-rect4 {-webkit-animation-delay: -0.9s;animation-delay: -0.9s;}
.sk-spinner-wave .sk-rect5 { -webkit-animation-delay: -0.8s;animation-delay: -0.8s;}

@-webkit-keyframes sk-waveStretchDelay {
  0%, 40%, 100% {-webkit-transform: scaleY(0.4);transform: scaleY(0.4);}
  20% { -webkit-transform: scaleY(1);transform: scaleY(1);}
}
@keyframes sk-waveStretchDelay {
  0%, 40%, 100% {-webkit-transform: scaleY(0.4);transform: scaleY(0.4);}
  20% { -webkit-transform: scaleY(1);transform: scaleY(1);}
}

/* 3.2 Parallax bg */
.parallax-window {
    min-height: 200px;
	background: #000000;;
	position:relative;
}
.parallax-window#home {
    min-height: 550px;
}
.parallax-window#short {
    height: 350px;
	min-height:inherit;
}
.parallax-content{
	color:#fff;
	text-align:center;
	display: table;
    width:100%;
    height:470px;
}
.parallax-content .sub_content{
	 display: table-cell;
  	padding:0 15%;
   vertical-align: middle;
	text-align:center;
	font-size:16px;
}
.parallax-content .sub_content h3{
	margin:0 0 10px 0;
	font-size:60px;
	font-weight:300;
	color:#fff;
	font-family: 'Lato', "Roboto", Helvetica, Arial, sans-serif;
}
.parallax-content .sub_content i{
	font-size:60px;
}
.parallax-window-2 {
	height:auto;
	background: url(../images/bg_office.jpg) no-repeat center center;
	background-attachment:fixed;
	background-size:cover;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	position:relative;
}
.parallax-content-2 {
    display: table;
   width:100%;
  height:auto;
}
.parallax-content-2 > div{
  display: table-cell;
  padding:10% 15%;
   vertical-align: middle;
	text-align:center;
	color:#fff;
	font-size:16px;
}
.parallax-content-2 div h3{
	margin:0 0 10px 0;
	font-size:60px;
	font-weight:300;
	color:#fff;
	font-family: 'Lato', "Roboto", Helvetica, Arial, sans-serif;
}
.parallax-content-2 div p{
	margin-bottom:0;
}
.parallax-content-2 div i{
	font-size:60px;
}
/* 3.3 Position  breadcrumbs */
#position {
	background-color:#fff;
	border-bottom:1px solid #ededed;
	padding:10px 0;
	font-size:11px;
}
#position ul li:first-child{
	padding-left:20px;
}
#position ul li:first-child:before{
	content:"\eaf4";
	font-style: normal;
  	font-weight: normal;
  	font-family: "fontello";
  	position:absolute;
  	left:0;
 	 top:1px;
  	color:#999;
}
#position ul{
	margin:0;
	padding:0;
	color:#888;
}
#position ul li a{
	color:#252525;
	opacity:0.7;
}
#position ul li a:hover{
	color:#252525;
	opacity:1;
}
#position ul li{
	display:inline-block;
	padding-right:8px;
	margin-right:3px;
	position:relative;
}
#position ul li:after{
	content:"\e9ee";
	font-style: normal;
	font-weight: normal;
	font-family: "fontello";
	position:absolute;
	right:0;
	top:1px;
}
#position ul li:last-child:after{
	content:'';
}
.margin_fix {
	margin-top:60px;
}

/*3.4 Login/Register modals*/
#message-review .error_message{
	color:#FF9;
	position:relative;
	top:-30px;
}
.form-control.form-white {
	color: #fff;
	font-size: 16px;
	height:42px;
	position: relative;
	border-radius: 0;
	border: 1px solid white;
	line-height:42px;
	background: transparent;
	-webkit-transition: background-color 0.3s;
	transition: background-color 0.3s;
	box-shadow: 0;
	-webkit-appearance: none;
   -moz-appearance:    none;
   appearance:         none;   
}
select.form-control.form-white{
	-webkit-appearance: none;
   -moz-appearance:    none;
   appearance:         none;   
   -webkit-border-radius: 0;
   -moz-border-radius: 0;
   box-shadow:0;
   border-radius: 0;
   height:42px;
   line-height:28px;
}
.modal-dialog #review .form-control.form-white{
	margin-bottom:0;
}
.form-control.form-white::-webkit-input-placeholder {
	color: #fff;
}
.form-control.form-white:-moz-placeholder {
	opacity: 1;
	color: #fff;
}
.form-control.form-white::-moz-placeholder {
	opacity: 1;
	color: #fff;
}
.form-control.form-white:-ms-input-placeholder {
	color: #fff;
}
.form-control.form-white:focus {
	background: rgba(255,255,255,0.2);
	 -webkit-box-shadow: none;
 	 box-shadow: none;
}
.login_icon {
	color:#fff;
	font-size:60px;
	opacity:0.5;
}
.modal-backdrop {
	z-index:999999;
}
.modal {
	padding: 0 25px !important;
	z-index:9999999;
}
.modal-dialog {
	/* width: 100%; */
	max-width: 560px;
	margin: 0 auto;
}
.modal-dialog #review {
	width: 100%;
	max-width: 650px;
}
.modal-popup {
	position: relative;
	padding:1px 30px 10px 30px;
	text-align: center;
	background: #78cfcf;
	border-radius: 0px;
	border:none;
}
.modal-popup a{
	color:#fff;
}
.modal-popup a:hover{
	color:#fff;
	text-decoration:underline;
}
.modal-popup a.close-link {
	font-size: 22px;
	position: absolute;
	top: 2px;
	right: 5px;
	color: #fff;
}
.modal-popup a.close-link:hover {
	color: #333;
	text-decoration:none;
}
.popup-form {
	width: 90%;
	max-width: 375px;
	margin: 60px auto;
}
/* .popup-form .form-control {
	margin: 20px 0;
} */
s.checkbox-holder {
	white-space: nowrap;
}
.checkbox {
	position: relative;
	display: block;
}
.checkbox {
	position: relative;
}
.checkbox label:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 20px;
	height: 20px;
	cursor: pointer;
	border: 2px solid #000;
	background: transparent;
	-webkit-transition: background-color 0.3s;
	transition: background-color 0.3s;
}
.checkbox input[type=checkbox]:focus + label:before {
	background: rgba(255,255,255,0.2);
}
.checkbox label:after {
	content: '';
	position: absolute;
	top: 6px;
	left: 6px;
	width: 8px;
	height: 8px;
	opacity: 0;
	background: #000;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.checkbox input[type=checkbox] {
	opacity: 0;
	position: absolute;
	width: 0;
	height: 0;
}
.checkbox input[type=checkbox]:checked + label:after {
	opacity: 1;
}
.checkbox-holder span {
	position: relative;
	display: inline-block;
	margin: 0 0 0 10px;
	white-space: normal;
	color: #fff;
}
.btn.btn-submit {
	width: 100%;
	margin-top: 15px;
	color: #ffffff;
	padding:10px;
	background: #787878;
	font-weight:600;
	outline:none;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
	font-size:16px;
}
.btn.btn-submit:focus {
	outline:none;
}
.btn.btn-submit:hover {
	color: #ffffff;
	background: #000000;
}

/*3.5 Spacing*/
.add_bottom_15 {margin-bottom:15px;}
.add_bottom_30 {margin-bottom:30px;}
.add_bottom_45 {margin-bottom:45px;}
.add_bottom_60 {margin-bottom:60px;}
.add_bottom_75 {margin-bottom:75px;}
.add_top_20 {padding-top:20px;}
.add_top_60 {padding-top:60px;}
.margin_60 {padding-top:60px; padding-bottom:60px;}
.margin_60_35 {padding-top:20px; padding-bottom:35px;}
.margin_80_35 {padding-top:80px; padding-bottom:10px;}
.margin_30 {margin-top:30px; margin-bottom:30px;}
.nomargin_top {margin-top:0;}
.nopadding {margin: 0 !important;padding: 0 !important;}
.nomargin {margin: 0 !important;}


/* 3.6 Map */
.marker_info {
    width: 250px;
    height: 280px;
	padding:20px;
    border-radius: 3px;
	text-align:center;
	background: #FFF;
    background: rgba(255, 255, 255, 0.9);
    position: relative;
    z-index: 9999999;
	font-family:"Roboto", Helvetica, Arial, sans-serif;
}
.marker_info img {
    display: block;
	margin:auto;
}
.marker_info h3 {
	font-size:16px;
	line-height:18px;
	text-transform:uppercase;
	margin-bottom:3px;
}
.marker_info em {
	margin-bottom:10px;
	display:inline-block;
	font-size:13px;
	color:#999;
}
.marker_info span {
    display: block;
    padding: 0;
    font-size: 12px;
	line-height:16px;
	color:#555;
	margin-bottom:15px;
}
.marker_info:after{
	right: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(0, 0, 0, 0);
	border-right-color: rgba(255, 255, 255, 0.9);
	border-width: 10px;
	margin-top: -10px;
}

/* 3.7 Password strength */   
#pass-info{
	width: 100%;
	margin-bottom:15px;
	color: #829CBD;
	text-align: center;
	font: 12px/25px Arial, Helvetica, sans-serif;
}
#pass-info.weakpass{
	border: 1px solid #FF9191;
	background: #FFC7C7;
	color: #94546E;
	text-shadow: 1px 1px 1px #FFF;
}
#pass-info.stillweakpass {
	border: 1px solid #FBB;
	background: #FDD;
	color: #945870;
	text-shadow: 1px 1px 1px #FFF;
}
#pass-info.goodpass {
	border: 1px solid #C4EEC8;
	background: #E4FFE4;
	color: #51926E;
	text-shadow: 1px 1px 1px #FFF;
}
#pass-info.strongpass {
	border: 1px solid #6ED66E;
	background: #79F079;
	color: #348F34;
	text-shadow: 1px 1px 1px #FFF;
}
#pass-info.vrystrongpass {
	border: 1px solid #379137;
	background: #48B448;
	color: #CDFFCD;
	text-shadow: 1px 1px 1px #296429;
}

/* 3.8 Input form style */   
.loader { margin-left:5px; position:absolute;}
.error_message { color:#F33; font-weight:600; margin-bottom:4px;}
.form-control {
  color: #333;
  height:40px;
  border-radius: 3px;
  box-shadow:none;
  border:1px solid #ddd;
  box-shadow: 0;
}
.form-group {
	margin-bottom:20px;
}
footer .form-group {
	margin-bottom:15px;
}
.payment_select{
	background-color:#f0f0f0;
	padding:15px 15px 10px 15px;
	margin-bottom:20px;
	border-radius: 3px;
}
.payment_select label{
	font-size:16px;
}
.payment_select i{
	float:right;
	font-size:24px;
}
.payment_select#paypal{
	background:#f0f0f0 url(../images/paypal.svg) center right no-repeat;
}

/*3.9 Collapse*/   
.panel {
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow:none;
  border-color:#ededed;
}
.panel-title a {display:block; color:#444;}
.panel-default > .panel-heading {
  background-color: #f9f9f9;
  padding:20px;
  border-bottom:1px solid #ddd;
}
.panel-heading {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.panel-group .panel {
  border-radius: 3px;
}
.panel-body {
	padding:30px;
	line-height:1.6 !important;
}
.panel-title a .indicator{
	color:#F7DF4E;
}
.panel-title a:hover .indicator{
	color:#333;
}

/*3.10 Tabs*/   
.tab-content{
	padding:30px; 
	background-color:#fff; 
	border:1px solid #ddd; 
	border-top:none; 
	margin-bottom:25px;
	-webkit-border-bottom-right-radius: 3px;
	-webkit-border-bottom-left-radius: 3px;
	-moz-border-radius-bottomright: 3px;
	-moz-border-radius-bottomleft: 3px;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}
/*3.11 Tooltips*/   
.tooltip_styled {
	display: inline;
	position: relative;
	z-index: 999;
}
.tooltip-item {
	cursor: pointer;
	display: inline-block;
}
.tooltip-item::after {
	content: '';
	position: absolute;
	width: 180px;
	height: 20px;
	bottom: 100%;
	left: 50%;
	pointer-events: none;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}
.tooltip_styled:hover .tooltip-item::after {
	pointer-events: auto;
}
.tooltip-content {
	position: absolute;
	z-index: 9999;
	width: 180px;
	left: 50%;
	margin: 0 0 20px -90px;
	bottom: 100%;
	text-align: left;
	line-height:1.2;
	display: block;
	padding: 15px;
	font-size:13px;
	text-align:center;
	font-weight:600;
	color: #fff;
	box-shadow: -5px -5px 15px rgba(48,54,61,0.2);
	background: #333;
	opacity: 0;
	cursor: default;
	pointer-events: none;
}
.tooltip-effect-1 .tooltip-content {
	-webkit-transform: translate3d(0,-10px,0);
	transform: translate3d(0,-10px,0);
	-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
	transition: opacity 0.3s, transform 0.3s;
}
.tooltip-effect-2 .tooltip-content {
	-webkit-transform-origin: 50% calc(100% + 10px);
	transform-origin: 50% calc(100% + 10px);
	-webkit-transform: perspective(1000px) rotate3d(1,0,0,45deg);
	transform: perspective(1000px) rotate3d(1,0,0,45deg);
	-webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
	transition: opacity 0.2s, transform 0.2s;
}
.tooltip-effect-3 .tooltip-content {
	-webkit-transform: translate3d(0,10px,0) rotate3d(1,1,0,25deg);
	transform: translate3d(0,10px,0) rotate3d(1,1,0,25deg);
	-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
	transition: opacity 0.3s, transform 0.3s;
}
.tooltip-effect-4 .tooltip-content {
	-webkit-transform-origin: 50% 100%;
	transform-origin: 50% 100%;
	-webkit-transform: scale3d(0.7,0.3,1);
	transform: scale3d(0.7,0.3,1);
	-webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
	transition: opacity 0.2s, transform 0.2s;
}
.tooltip_styled:hover .tooltip-content {
	pointer-events: auto;
	opacity: 1;
	-webkit-transform: translate3d(0,0,0) rotate3d(0,0,0,0);
	transform: translate3d(0,0,0) rotate3d(0,0,0,0);
}
.tooltip_styled.tooltip-effect-2:hover .tooltip-content {
	-webkit-transform: perspective(1000px) rotate3d(1,0,0,0deg);
	transform: perspective(1000px) rotate3d(1,0,0,0deg);
}
.tooltip-content::after {
	content: '';
	top: 100%;
	left: 50%;
	border: solid transparent;
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: transparent;
	border-top-color: #333;
	border-width: 10px;
	margin-left: -10px;
}
.tooltip-content .label {
	margin-left:10px;
}
.tooltip-content i{
	font-size:20px !important;
	top:3px !important;
	left:-3px !important;
	position:relative;
}
.tooltip-content h4 {
	display:block;
	margin: 0 0 10px 0;
	line-height:14px;
	font-size:14px;
	color:#fff;
	text-transform:uppercase;
}
.tooltip-content a {
	font-weight: bold;
}

/*3.12 Blockquote*/
blockquote.styled {
	font-family:Georgia, "Times New Roman", Times, serif;
}

.white_bg{
	background-color:#fff;
}

/*============================================================================================*/
/* 4.  UPDATES */
/*============================================================================================*/
/*4.1 Strip list home*/
a.strip_list{
	color:#555;
	-webkit-transition: all 300ms ease-in-out; 
	-moz-transition: all 300ms ease-in-out; 
	-o-transition: all 300ms ease-in-out; 
}
a.strip_list:hover{
	 transform: translate(0, -5px); 
	 -webkit-transform: translate(0, -5px); 
	 -ms-transform: translate(0, -5px); 
	 -webkit-box-shadow: 0px 2px 0px 0px #ebedef; 
	 -moz-box-shadow: 0px 2px 0px 0px #ebedef; 
	 box-shadow: 0px 2px 0px 0px #ebedef; 
}
/*4.2 Grid list*/
.strip_list.grid .desc{
	padding-left:0;
	text-align:center;
}
.strip_list.grid .thumb_strip{
	display:block;
	position:static;
	margin:auto;
	margin-bottom:15px;
	width:140px;
	height:140px;
}
.strip_list.grid .thumb_strip img{
	width:128px;
	height:auto;
}
.strip_list.grid .desc ul{
	margin:10px 0 0 0;
}
/*4.3 General*/
a.load_more_bt{
	display:block;
	padding:10px;
	text-align:center;
	border:3px solid #fff;
	color:#555;
	font-weight:600;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}
a.load_more_bt:hover{
	background-color:#fff;
	color:#F7DF4E;
}
#tools{
	background:#fff;
	margin-bottom:25px;
	height:38px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	border:1px solid #ededed;
}
a.bt_filters{
	height:36px;
	width:36px;
	color:#555;
	display:inline-block;
	text-align:center;
	line-height:36px;
	float:right;
	font-size:16px;
	border-left: 1px solid #ededed;
}
#tools .styled-select select {
   background: transparent;
   width:115%;
   padding:7px 5px 5px 15px;
   border: 0;
   border-radius: 0;
   height: 31px;
   margin:0;
  	font-weight:600;
	font-size:11px;
	-moz-appearance: window;
    -webkit-appearance: none;
    cursor: pointer;
	color:#444;
	box-shadow:none;
}
#tools .styled-select {
   width: 100%;
   overflow:hidden;
   height: 36px;
   background: url(../images/arrow_down.svg) no-repeat  right center ;
   border:none;
   border-right: 1px solid #ededed;
} 

.infoBox {padding-right:30px !important;}

/*4.4 Map listing */
header#map_listing_header{
	background-color: #78cfcf;
	position:fixed;
	-webkit-box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.41);
	-moz-box-shadow:0px 2px 10px -2px rgba(0, 0, 0, 0.41);
	box-shadow:0px 2px 10px -2px rgba(0, 0, 0, 0.41);
}
.full-height {height:100%;}
.row-height{
    height:100%;
    min-height:100%;
}
.map-right{
    height:100%;
    min-height:100%;
	padding:0px;
}
.content-left{
    height:100%;
    min-height:100%;
	overflow-y:scroll;
	padding:50px 30px 30px 30px;
	background-color:#fff;
	border-right: 1px solid #ededed;
}
#map_listing{
	width:100%;
	height:100%;
}
#collapseFiltesmap .filter_type{
	clear:both;
	width:100%;
	border-top: 1px solid #ddd;
}
#collapseFiltesmap .filter_type .range_wp{
	float:left;
	width:80%;
	padding-left:30px;
	padding-bottom:20px;
	padding-top:5px;
}
#collapseFiltesmap .filter_type h6{
	float:left;
	width:20%;
	border:0;
	padding-top:10px;
}
#collapseFiltesmap .filter_type ul{
	float:left;
	width:80%;
	margin:0 0 15px 0;
	padding:0;
	padding-top:20px;
}
#collapseFiltesmap .filter_type ul li {
	display:inline-block;
	min-width:190px;
	margin-left:45px;
}
#collapseFiltesmap .filter_type ul li label{
	font-weight:normal;
	font-size:13px;
}

#filters_map{
	border-bottom:1px solid #ededed;
	margin:0 -30px 30px -30px;
	background-color:#f8f8f8;
	padding:15px 30px 0 30px;
}
a.btn_filter{
	padding:5px;
	background-color:#fff;
	border:1px solid #ededed;
	display:inline-block;
	line-height:1;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	margin-bottom:15px;
}
a.btn_filter:before{
	font-size:30px;
	color:#999;
	padding:0;
}
a.btn_filter#open_filters:before{
	font-family: "ElegantIcons";
  	content: "\66";
}
a.btn_filter#list:before{
	font-family: "ElegantIcons";
  	content: "\64";
	font-size:20px;
}
a.btn_filter#grid, a.btn_filter#list{
	margin-top:5px;
}
a.btn_filter#grid:before{
  	font-family: "ElegantIcons";
	content: "\e025";
	font-size:20px;
}
a.btn_filter#grid{
	margin-right:5px;
}
a.btn_listing{
	border: none;
	font-family: inherit;
	font-size: inherit;
	color:#777;
	cursor: pointer;
	font-weight:500;
	background-color:#f0f0f0;
	padding: 10px 20px;
	margin-top:5px;
	outline: none;
	font-size:12px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	font-weight:500;
	display:inline-block;
	text-align:center;
}
a.btn_listing:hover{
	background-color:#333;
	color:#fff;
}
a.load_more_bt_2{
	display:block;
	padding:10px;
	text-align:center;
	border:none;
	background-color:#f0f0f0;
	color:#555;
	font-weight:500;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}
a.load_more_bt_2:hover{
	background-color:#333;
	color:#fff;
}

/*4.5 Search modal  */
#position {
	background-color:#fff;
	border-bottom:1px solid #ededed;
	padding:12px 0;
	font-size:13px;
}
#position ul{
	display:inline-block;
}
a.search-overlay-menu-btn {
	display: inline-block;
	padding: 0 0 0 10px;
	color:#999;
	font-size:13px;
	border-left: 1px solid #ddd;
}
a.search-overlay-menu-btn:hover {
	color: #F7DF4E;
}
a.btn_filter#search_bt{
	padding:7px;
	margin:0 4px 0 0;
	position:relative;
	top:-3px;
}
a.btn_filter#search_bt:before{
  	font-family: "fontello";
	content: "\ee33";
	font-size:17px;
}
.search-overlay-menu {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.9);
	-webkit-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
	-moz-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
	-ms-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
	-o-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
	transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
	opacity: 0;
	visibility: hidden;
	z-index: 999999;
	text-align: center;
}
.search-overlay-menu.open {
	opacity: 1;
	visibility: visible;
	-webkit-transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
	-moz-transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
	-ms-transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
	-o-transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
	transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
}
.search-overlay-menu form {
	height: 70px;
	position: absolute;
	top: 50%;
	left: 0;
	margin: -35px auto 0;
	width: 60%;
	left: 20%;
	transform: translateY(20px);
	-moz-transform: translateY(20px);
	-ms-transform: translateY(20px);
	-o-transform: translateY(20px);
	-webkit-transform: translateY(20px);
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
}
.search-overlay-menu.open form {
	transform: translateY(0px);
	-moz-transform: translateY(0px);
	-ms-transform: translateY(0px);
	-o-transform: translateY(0px);
	-webkit-transform: translateY(0px);
}
.search-overlay-menu input[type="search"] {
	height: 100%;
	width: 100%;
	color: #fff;
	background: transparent;
	border-top: 0px solid transparent;
	border-bottom: 2px solid #909090 !important;
	border-left: 0px solid transparent;
	border-right: 0px solid transparent;
	font-size: 42px;
	font-weight: 300;
	outline: none;
	padding: 10px 0 10px 0;
}
.search-overlay-menu form button {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	border: medium none;
	font-size: 3rem;
	position: absolute;
	right: 0;
	top: 15px;
	color: #c6c6c6;
	outline: none;
}
.search-overlay-menu .search-overlay-close {
	position: absolute;
	top: 5px;
	right: 20px;
	opacity: 1;
	font-size: 40px;
	color: #fff;
}
.search-overlay-menu .search-overlay-close:hover {
	color: #F7DF4E;
	cursor: pointer;
}

/*4.6 Restaurant menu  */
.thumb_menu_list{
	width:60px;
	height:60px;
	overflow:hidden;
	text-align:center;
	display:inline-block;
	float:left;
	margin-right:15px;
	border:1px solid #fff;
	border-radius:3px;
}

@media screen and (min-width: 320px) {
	.thumb_menu_list{
		  display:none !important;
 }
}

@media screen and (max-width: 768px) {
	.thumb_menu_list  {
		  display:none !important;
 }
}
.thumb_menu_list img{
	width:60px;
	height:auto;
}


@media screen and (min-width: 320px) {
	.thumb_menu_list{
		  display:none !important;
 }
}

@media screen and (max-width: 768px) {
	.thumb_menu_list  {
		  display:none !important;
 }
}

.dropdown-options .dropdown-menu{
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 5px 20px 10px 20px;
  width:200px;
  font-size:12px;
  font-weight: normal;
  text-shadow:none;
  text-transform:none !important;
  border-top: 2px solid #333333;
  margin-left:-86px;
}

 .dropdown-options .dropdown-menu:before{
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-bottom-color: #333333;
	border-width: 7px;
	margin-left: -7px;
}
 .dropdown-options .dropdown-menu{
	margin-top:10px;
	
}
 .dropdown-options .dropdown-menu h5{
	 border-bottom:1px solid #ededed;
	 padding-bottom:5px;
	 margin-bottom:5px !important;
	 font-size:13px;
}
 .dropdown-options .dropdown-menu label{
	 font-weight:500;
	 color:#555 !important;
}
 .dropdown-options .dropdown-menu label{
	 font-weight:500;
	 color:#555 !important;
	 width:100%;
}
 .dropdown-options .dropdown-menu label input{
	 margin-right:5px;
}
 .dropdown-options .dropdown-menu label span{
	 float:right !important;
	 display:inline-block;
	 text-align:right;
}
 .dropdown-options .dropdown-menu a.add_to_basket{
	 background-color:#f8f8f8;
	 border-radius:3px;
	 display:block;
	 font-weight:500;
	 text-align:center;
	 padding:5px 10px;
	 margin-top:15px;
	 color:#333333
}
 .dropdown-options .dropdown-menu a.add_to_basket:hover{
	 background-color:#333333;
	 color:#fff;
}

/*Drop-down menu options position */
 .dropdown-options.dropup .dropdown-menu{
	 border-top:0; 
	 border-bottom: 2px solid #333333;
	 margin-bottom: 10px;
}
 .dropdown-options.dropup .dropdown-menu:before{
	top: 100%;
	left: 50%;
	border-color: #333333 transparent transparent transparent;
	border-bottom-color: #333333;
	border-width: 7px 7px 0 7px;
	margin-left: -7px;
}
/*4.7 Work with us section  */
a.box_work{
	text-align:center;
	background-color:#fff;
	padding:30px;
	font-size:14px;
	transition: all 300ms ease-in-out; 
	-webkit-transition: all 300ms ease-in-out; 
	-moz-transition: all 300ms ease-in-out; 
	-o-transition: all 300ms ease-in-out; 
	-webkit-box-shadow: 0px 3px 0px 0px #f0f2f4; 
	-moz-box-shadow: 0px 3px 0px 0px #f0f2f4; 
	box-shadow: 0px 3px 0px 0px #f0f2f4;
	color:#666;
	margin-bottom:30px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	display:block;
}
a.box_work:hover{
	 transform: translate(0, -10px); 
	 -webkit-transform: translate(0, -10px); 
	 -ms-transform: translate(0, -10px); 
	 -webkit-box-shadow: 0px 3px 0px 0px #ebedef; 
	 -moz-box-shadow: 0px 3px 0px 0px #ebedef; 
	 box-shadow: 0px 3px 0px 0px #ebedef; 
}
a.box_work h3{
	font-size:24px;
}
a.box_work h3 span{
	display:block;
	font-size:18px;
	margin-top:3px;
	color:#999;
	font-weight:300;
}
a.box_work .btn_1{
	margin-top:30px;
}
ul.list_ok {
	list-style: none;
	margin: 0 0 20px 0;
	padding: 0;
	line-height: 22px;
}

ul.list_ok li {
	position: relative;
	padding-left: 30px;
	margin-bottom:15px;
}

ul.list_ok li:before {
	font-style: normal;
	font-weight: bold;
	font-family: "ElegantIcons";
	font-size: 18px;
	content: "\e052";
	color: #fff;
	position: absolute;
	left: 0;
	top: 0;
}
.features-bg.img_2 {
    position: relative;
    min-height: 400px;
    background: url(../images/delivery_2.jpg) no-repeat center center;
    background-size: cover;
}
.feature_2 h3{margin-top:0; font-size:21px;}
.feature_2 {
	padding:30px 30px 20px 100px;
	position:relative;
	background:#fff;
	margin-bottom:30px;
	color:#666;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
}
.feature_2 i{
	margin:0;
	position:absolute; 
	top:20px;
	left:20px;
	padding:0;
	margin:0;
	width:65px;
	height:65px;
	line-height:60px;
	text-align:center;
	font-size:38px;
	color:#6dcff6;
}
label.margin_left{
	margin-left:15px !important;
}

/*Pricing tables*/
.plans {
	margin: 0px auto 50px;
	zoom: 1;
}
.plans:before,
.plans:after {
	content: '';
	display: table;
}
.plans:after {
	clear: both;
}
.plan {
	margin: 10px 0;
	padding: 20px;
	text-align: center;
	background: #fafafa;
	background-clip: padding-box;
	border: solid #dddddd;
	min-height: 380px;
	border-width: 1px 0 1px 1px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	background-color: #fff;
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.col-md-4.plan:first-child {
	margin-right: -30px;
	margin-left: 30px;
}
.plan:last-child {
	border-width: 1px;
}
.plan-title {
	position: relative;
	margin: -20px -10px 20px;
	padding: 20px;
	text-transform:uppercase;
	line-height: 1;
	font-size: 18px;
	color:#555;
	font-weight: bold;
	border-bottom: 1px dotted #ccc;
}
.plan-title:before {
	content: '';
	position: absolute;
	bottom: -1px;
	left: 0;
	right: 0;
	height: 1px;
}
.plan-price {
	margin: 0 auto 20px;
	width: 110px;
	height: 110px;
	line-height: 110px;
	font-size: 30px;
	font-weight: bold;
	color: white;
	background: #4b4b4b;
	border-radius: 100px;
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	display: inline-block;
	text-align: center;
	font-family: "Roboto", Arial;
}
.plan-price > span {
	font-size: 12px;
	font-weight: normal;
	color: rgba(255, 255, 255, 0.9);
}
.plan-features {
	margin-bottom: 20px;
	line-height: 2;
	font-size: 12px;
	text-align: center;
}
ul.plan-features {
	padding: 0;
	margin: 0 0 15px 0;
}
.plan-features li {
	list-style: none;
}
.plan-tall {
	margin: 0;
	background: #fff;
	border-width: 1px;
	border-radius: 10px;
	margin-right: -30px;
	position: relative;
	z-index: 100;
	border: solid #83c99f;
	position: relative;
}
.ribbon_table {
	width: 99px;
	height: 97px;
	position: absolute;
	left: -7px;
	top: -7px;
	display: block;
	background: url(../images/ribbon.png) no-repeat 0 0;
	z-index: 101;
}
.plan-tall > .plan-title {
	font-size: 18px;
}
.plan-tall > .plan-price {
	margin: 0 auto 20px;
	height: 130px;
	width: 130px;
	line-height: 130px;
	font-size: 36px;
	font-weight: bold;
	color: white;
	background: #F7DF4E;
	border-radius: 130px;
	-webkit-border-radius: 130px;
	-moz-border-radius: 130px;
	display: inline-block;
	text-align: center;
}
.plan-tall > .plan-features {
	font-size: 14px;
}
.plan-tall > .plan-button {
	padding: 0 16px;
	line-height: 32px;
}
.plan-tall + .plan {
	border-left: 0;
}

