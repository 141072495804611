@import url("animate.min.css"); 								/* Animate */
@import url("menu.css");										/* Menu styles */

@import url("elegant_font/elegant_font.min.css");				/* Icon pack */
@import url("fontello/css/fontello.min.css");					/* Icon pack */
@import url("magnific-popup.css");								/* Lightbox & dialog script */
@import url("pop_up.css");										/* Popup Advertising */

@import url("admin.css");

@import url("ion.rangeSlider.css");
@import url("ion.rangeSlider.skinFlat.css");
@import url("skins/square/grey.css");
@import url("style.css");
@import url("responsive.css");	
@import url("custom.css");	
@import url("color_scheme.css");
@import url("bootstrap3-wysihtml5.min.css");
@import url("dropzone.css");